import React, { ReactElement } from 'react';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import styles from './style.module.scss';
const Layout = dynamic(() => import('@/layout/Layout'));
import { commonService } from '@/services/commonService';
import { NextPageWithLayout } from '@/types/NextPageWithLayout';
import { useSelector } from 'react-redux';
import { getIsUserAuthorized } from '@/store/features/userSlice';
import { globalCache } from '@/utils/cache';
import { apiService } from '@/services/ApiService';
import { generateHomeSchema } from '@/utils/schema';
const HocHead = dynamic(() => import('@/components/HOC/HocHead'));
const Banner = dynamic(() => import('@/components/home/banner'));
const TopPicks = dynamic(() => import('@/components/home/top-picks'));
const CallUs = dynamic(() => import('@/components/home/home-bottom-call'));
const PicksCollection = dynamic(
  () => import('@/components/home/mobile/picks-collection-mobile')
);
const DeliveryMobile = dynamic(
  () => import('@/components/home/mobile/delivery-mobile')
);
const FinestBrand = dynamic(() => import('@/components/home/finest-brand'));
const FinestBrandMobile = dynamic(
  () => import('@/components/home/mobile/finest-brand-mobile')
);
const HomeSofasetMobile = dynamic(
  () => import('@/components/home/mobile/sofa-set-mobile')
);
const ShopRoomsMobile = dynamic(
  () => import('@/components/home/mobile/shop-rooms-mobile')
);
const Recommended = dynamic(() => import('@/components/home/recommended'), {
  ssr: false,
});
const Recommendedmob = dynamic(
  () => import('@/components/home/mobile/recommended'),
  { ssr: false }
);
const TopSellers = dynamic(() => import('@/components/home/top-sellers'));
const TopSellersMobile = dynamic(
  () => import('@/components/home/mobile/top-sellers-mobile')
);
const BeautifyLivingSpaces = dynamic(
  () => import('@/components/home/beautify-living-spaces')
);
const NewCollectionMobile = dynamic(
  () => import('@/components/home/mobile/new-collection-mobile')
);
const NewLaunchesMobile = dynamic(
  () => import('@/components/home/mobile/launche-mobile')
);
const ModularFurnitureMobile = dynamic(
  () => import('@/components/home/mobile/modular-furniture-mobile')
);
const EssentialFurnitureMobile = dynamic(
  () => import('@/components/home/mobile/essential-furniture-mobile')
);
const FurnishingMobile = dynamic(
  () => import('@/components/home/mobile/furnishing-mobile')
);
const WoodenSofaMobile = dynamic(
  () => import('@/components/home/wooden-sofa-mobile')
);
const OutDoorMobile = dynamic(
  () => import('@/components/home/mobile/outdoor-mobile')
);
const EssentialFurniture = dynamic(
  () => import('@/components/home/essential-furniture')
);
const Stores = dynamic(() => import('@/components/home/stores'));
const StoresMobile = dynamic(
  () => import('@/components/home/mobile/stores-mobile')
);
// const NewCollections = dynamic(
//   () => import('@/components/home/new-collections')
// );
const HomeFurnishing = dynamic(
  () => import('@/components/home/home-furnishing')
);
// const NewArrivals = dynamic(() => import('@/components/home/new-arrivals'));
const ModularFurniture = dynamic(
  () => import('@/components/home/moduler-furniture-design')
);
// const BuyInBulk = dynamic(() => import('@/components/home/buy-in-bulk'));
const BuyInBulkMobile = dynamic(
  () => import('@/components/home/mobile/buy-in-bulk-mobile')
);
const HappyWords = dynamic(() => import('@/components/home/happy-words'), {
  ssr: false,
});
const HappyWordsMobile = dynamic(
  () => import('@/components/home/mobile/happy-words-mobile'),
  { ssr: false }
);
const HomeText = dynamic(() => import('@/components/home/home-text'));
// const BuyFurnitureMobile = dynamic(
//   () => import('@/components/home/mobile/buy-furniture-mobile')
// );
const ClientMobile = dynamic(
  () => import('@/components/home/mobile/client-mobile')
);
const NearestStore = dynamic(
  () => import('@/components/home/mobile/nearest-store-mobile')
);
const OffersMobile = dynamic(
  () => import('@/components/home/mobile/offers-mobile')
);
const TopPicksMobile = dynamic(
  () => import('@/components/home/mobile/top-picks-mobile')
);
const BeautifyLivingSpacesMobile = dynamic(
  () => import('@/components/home/mobile/beautify-living-spaces')
);
const BannerMobile = dynamic(
  () => import('@/components/home/mobile/banner-mobile')
);
const News = dynamic(() => import('@/components/home/News'));
// const FooterMobile = dynamic(
//   () => import('@/layout/components/mobile/footer-mobile')
// );
const HotelFurniture = dynamic(
  () => import('@/components/home/hotel-furniture')
);

const ShopNow = dynamic(() => import('@/components/home/shop-now'));
const HomeDecoritems = dynamic(
  () => import('@/components/home/home-decor-items')
);

interface CachedData {
  homePageJson: any;
  infoData: any;
}
const baseUrl = process.env.NEXT_PUBLIC_API_BASE_URL;
export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const userAgent = ctx.req
    ? ctx.req.headers['user-agent']
    : navigator.userAgent;
  const isMobile = /mobile/i.test(userAgent || '');

  const cacheKey = `${isMobile ? 'mobile-home-page' : 'home-page'}`;
  const cacheTTL = 3600 * 24; // Cache for 60 seconds

  // Check if data exists in cache
  const cachedData: CachedData | null = globalCache.get(cacheKey);

  if (cachedData) {
    return {
      props: {
        infoData: JSON.parse(cachedData.infoData),
        homePageJson: JSON.parse(cachedData.homePageJson),
        error: null,
        isMobile,
      },
    };
  }

  if (process.env.NEXT_PUBLIC_CACHE_PAGES) {
    ctx.res.setHeader(
      'Cache-Control',
      'public, s-maxage=360000, stale-while-revalidate=59'
    );
  }

  // Fetch data from external API
  try {
    const pageJsonRes = await commonService.getJSONData(
      `webcontent/${isMobile ? 'mobile-home-page.json' : 'home-page.json'}`
    );
    // const res = await fetch(`${baseUrl}/information/203`);
    const apiRes = await apiService.get(`${baseUrl}/information/203`);
    // const apiRes = await res.json(`${baseUrl}/information/203`);
    if (apiRes.code !== 200) {
      return {
        redirect: {
          destination: '/404',
          permanent: false,
        },
      };
    }

    let infoData: any = null;

    if (apiRes) {
      infoData = apiRes;
    }

    // Store the fetched data in the cache
    globalCache.set(
      cacheKey,
      {
        infoData: JSON.stringify(infoData),
        homePageJson: JSON.stringify(pageJsonRes),
      },
      cacheTTL
    );

    // Pass data to the page via props
    return {
      props: {
        infoData,
        homePageJson: pageJsonRes || null,
        error: null,
        isMobile,
      },
    };
  } catch (error) {
    console.error('Error fetching information:', error);
    // Pass data to the page via props
    return {
      props: {
        pageJsonRes: null,
        infoData: null,
        error: 'Failed to fetch information',
        isMobile,
      },
    };
  }
};

interface HomePageProps {
  infoData: any;
  error: string | null;
  isMobile: boolean;
  homePageJson: any;
}

const Home: NextPageWithLayout<HomePageProps> = ({
  infoData,
  isMobile,
  homePageJson = [],
}) => {
  const metaInfo = infoData?.data?.description;
  const isUserAuthorized = useSelector(getIsUserAuthorized);

  //getCookie('isUserAuthenticated');

  // const isMobile = useMediaQuery('(max-width: 768px)');
  /* const getHomePageJson = () => {
      isMobile
        ? commonService
            .getJSONData('webcontent/mobile-home-page.json')
            .then((response: any) => {
              setHomePageJson(response || []);
            })
        : commonService
            .getJSONData('webcontent/home-page.json')
            .then((response: any) => {
              setHomePageJson(response || []);
            });
    };
  
    useEffect(() => {
      getHomePageJson();
    }, []); */

  const getComponentData = (key: string) => {
    return homePageJson && homePageJson.length > 0
      ? homePageJson.find((com: any) => com.component == key) || {}
      : {};
  };

  const jsonLdData = generateHomeSchema();

  const hocHeadData = {
    meta: {
      title: metaInfo?.main_title,
      description: metaInfo?.meta_description,
    },
    jsonLdData: {
      home: jsonLdData,
    },
  };
  return (
    <>
      <HocHead seo={hocHeadData} />
      {isMobile ? (
        <>
          <div className={styles['home-wrapper']}>
            <TopPicksMobile data={getComponentData('top-picks')} />
            <BannerMobile data={getComponentData('top-banner')} />
            <DeliveryMobile data={getComponentData('delivery')} />
            <PicksCollection data={getComponentData('top-picks-for-you')} />
            <FinestBrandMobile data={getComponentData('finest-brand')} />
            <ShopRoomsMobile data={getComponentData('shop-rooms')} />
            <BeautifyLivingSpacesMobile
              data={getComponentData('beautify-living-space')}
            />
            <HomeSofasetMobile />
            {isUserAuthorized && (
              <Recommendedmob data={getComponentData('recommended-for-you')} />
            )}
            <TopSellersMobile data={getComponentData('best-sellers')} />
            <WoodenSofaMobile />
            <NewCollectionMobile data={getComponentData('new-collection')} />
            <NewLaunchesMobile data={getComponentData('new-launches')} />
            <ModularFurnitureMobile
              data={getComponentData('modular-furniture')}
            />
            <FurnishingMobile data={getComponentData('home-furnishing')} />
            <EssentialFurnitureMobile
              data={getComponentData('essential-furniture')}
            />
            <StoresMobile data={getComponentData('stores')} />
            <OutDoorMobile />
            <BuyInBulkMobile data={getComponentData('buy-in-bulk')} />
            <News />
            <HappyWordsMobile data={getComponentData('customer-stories')} />
            {/* <BuyFurnitureMobile /> */}
            <ClientMobile />
            <NearestStore />
            <OffersMobile />
            <CallUs />
            {/* <FooterMobile /> */}
          </div>
        </>
      ) : (
        <>
          <div className={styles['home-wrapper']}>
            <Banner data={getComponentData('top-banner')} />
            <TopPicks categoryData={getComponentData('top-picks')} />
            <FinestBrand data={getComponentData('finest-brand')} />
            {isUserAuthorized && (
              <Recommended data={getComponentData('recommended-for-you')} />
            )}
            <TopSellers data={getComponentData('top-sellers')} />
            <ShopNow />
            <EssentialFurniture
              data={getComponentData('essential-furniture')}
            />
            <Stores data={getComponentData('stores')} />
            <BeautifyLivingSpaces
              data={getComponentData('beautify-living-space')}
            />
            <HomeFurnishing data={getComponentData('home-furnishing')} />
            <HotelFurniture />
            <HomeDecoritems />
            <ModularFurniture data={getComponentData('modular-furniture')} />
            {/* <NewCollections data={getComponentData('new-collections')} /> */}
            {/* <NewArrivals data={getComponentData('new-arrivals')} />
            <BuyInBulk data={getComponentData('buy-in-bulk')} /> */}
            <News />
            <HappyWords data={getComponentData('happy-words')} />
            <HomeText data={getComponentData('home-text')} />
          </div>
        </>
      )}
    </>
  );
};

Home.getLayout = function getLayout(page: ReactElement, data: any) {
  return <Layout data={data}>{page}</Layout>;
};

export default Home;
